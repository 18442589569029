var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "heading-elements"
  }, [_c('ul', {
    staticClass: "list-inline mb-0"
  }, [_vm.availableActions.includes('collapse') ? _c('li', [_c('a', {
    class: {
      rotate: _vm.isCollapsed
    },
    attrs: {
      "data-action": "collapse"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('collapse');
      }
    }
  }, [_c('strong', [_c('h4', [_c('b-badge', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-1",
    attrs: {
      "variant": "info"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1 cursor-pointer",
    attrs: {
      "icon": "ChevronDownIcon",
      "size": "24"
    }
  }), _c('span', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(_vm.isCollapsed ? 'show' : 'hide'))])], 1)], 1)])])]) : _vm._e(), _vm.availableActions.includes('refresh') ? _c('li', [_c('a', {
    attrs: {
      "data-action": "reload"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('refresh');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "RotateCwIcon",
      "size": "24"
    }
  })], 1)]) : _vm._e(), _vm.availableActions.includes('close') ? _c('li', [_c('a', {
    attrs: {
      "data-action": "close"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "XIcon",
      "size": "24"
    }
  })], 1)]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }