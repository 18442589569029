<template>
  <div class="heading-elements">
    <ul class="list-inline mb-0">
      <li v-if="availableActions.includes('collapse')">
        <a
          data-action="collapse"
          :class="{ rotate: isCollapsed }"
          @click="$emit('collapse')"
        >
          <strong>
            <h4>
              <b-badge
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="info"
                class="px-1"
              >
                <feather-icon
                  class="mr-1 cursor-pointer"
                  icon="ChevronDownIcon"
                  size="24"
                />
                <span class="mr-1">{{ isCollapsed ? 'show' : 'hide' }}</span>
              </b-badge>
            </h4>
          </strong>
        </a>
      </li>
      <li v-if="availableActions.includes('refresh')">
        <a data-action="reload" @click="$emit('refresh')">
          <feather-icon class="cursor-pointer" icon="RotateCwIcon" size="24" />
        </a>
      </li>
      <li v-if="availableActions.includes('close')">
        <a data-action="close" @click="$emit('close')">
          <feather-icon class="cursor-pointer" icon="XIcon" size="24" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
export default {
  directives: {
    Ripple
  },
  props: {
    isCollapsed: {
      type: Boolean,
      requried: true
    },
    availableActions: {
      type: Array,
      default: () => []
    }
  }
}
</script>
